import React from 'react';
import { Tag, Typography } from 'antd';
import Card from './Card';

const { Text } = Typography;

function LeftTop() {
  const [tagValue, setTagValue] = React.useState(5);

  return (
    <></>
  );
}

export default LeftTop;

import React from 'react';
import './Roundtag.css';
const Roundtag = ({ backgroundColor, children }) => (
    <div
        className='roundtag'
        style={{ '--color': backgroundColor }}
    >
        <div className="roundtag__label">{children}</div>
        <div className="roundtag__round"></div>
    </div>
);
export default Roundtag;

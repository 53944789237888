import React from 'react';
import { Space, Table, Tag, Row, Col, Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';
import jsonData from '../json/data1.json';

const getColor = (num) => {
  // Todo:edit with specific number
  if (num <= 10) {
    return "green";
  } else if (num > 10 && num <= 20) {
    return "yellow";
  } else if (num > 20) {
    return "red";
  }
};

const getScoreColor = (num) => {
  // Todo:edit with specific number
  if (num >= 4) {
    return "red";
  } else if (num >= 2 && num < 4) {
    return "yellow";
  } else if (num < 2) {
    return "green";
  }
};

const scoInfo = (data) => {
  return (
    <Space>
      <Row gutter={[16, 16]}>
        <Col style={{ display: 'flex', alignItems: 'center' }}>
          <Tag color={getScoreColor(data.first)}>{data.first}</Tag>
        </Col>
        <Col>
          <Row>
            {data.sec}
          </Row>
          <Row>
            {data.third}
          </Row>
        </Col>
      </Row>
    </Space>
  )
}

const dt = (data) => {
  return (
    <Space>
      <Row gutter={[16, 16]}>
        <Col>
          <Row>
            {data.first}
          </Row>
          <Row>
            {data.second}
          </Row>
        </Col>
      </Row>
    </Space>
  )
}

const rInfo = (data) => {
  return (
    <Space>
      <Row gutter={[16, 16]}>
        <Col>
          <Row>
            <Tooltip title='test tooltip'>
              <Tag color={getColor(data.botleft)}>{data.topleft}</Tag>
            </Tooltip>
          </Row>
          <Row>
            {data.botleft}
          </Row>
        </Col>

        <Col>
          <Row>
            {data.topright}
          </Row>
          <Row>
            {data.botright}
          </Row>
        </Col>
      </Row>
    </Space>
  )
}

const columns = [
  {
    title: 'Patient Location',
    dataIndex: 'pLoc',
    key: 'pLoc'
  },
  {
    title: 'Room/Bed',
    dataIndex: 'rb',
    key: 'rb'
  },
  {
    title: 'Patient Name',
    dataIndex: 'pName',
    key: 'pName'
  },
  {
    title: 'Age/Gender',
    dataIndex: 'ag',
    key: 'ag'
  },
  {
    title: 'Patient Class',
    dataIndex: 'pClass',
    key: 'pClass'
  },
  {
    title: 'Private Encounter Flag',
    dataIndex: 'pFlag',
    key: 'pFlag'
  },
  {
    title: 'MEWS(Unvalidated) Scoring Info',
    dataIndex: 'scoInfo',
    key: 'scoInfo',
    render: scoInfo,
  },
  {
    title: 'Last(2) MEWs Scores(Auto-Calc) Date/Time',
    dataIndex: 'dt',
    key: 'dt',
    render: dt,
  },
  {
    title: 'Sepsis Risk(Predictive Score) Info',
    dataIndex: 'rInfo',
    key: 'rInfo',
    render: rInfo,
  },
  {
    title: 'Sepsis Risk Communications',
    key: 'rComm',
    dataIndex: 'rComm',
  },
  {
    title: 'Sepsis Bundle Status - Last 2 Filed',
    key: 'status2',
    dataIndex: 'status2',
  },
  {
    title: 'New Rslt Flag',
    key: 'flag',
    render: (_, record) => (
      <Space size="middle">
        <a>icon1{record.name}</a>
      </Space>
    ),
  },
  {
    title: 'New Notes',
    key: 'nNotes',
    render: (_, record) => (
      <Space size="middle">
        <a>icon2{record.name}</a>
      </Space>
    ),
  }
];

const data = [
  {
    key: '1',
    pLoc: 'ED',
    rb: 'E78-02',
    pName: jsonData.patient_profile.name,
    ag: jsonData.patient_profile.gender + " 65",
    pClass: 'Inpatient',
    pFlag: 'Yes',
    scoInfo: {
      first: 5,
      sec: 5,
      third: 'Never r...',
    },
    dt: {
      first: '2/26/18 02:50[5]',
      second: '2/26/18 02:20[5]',
    },
    rInfo: {
      topleft: jsonData.patient_profile.t + '%',
      botleft: jsonData.patient_profile.t, topright: '-',
      botright: '-',
    },
    rComm: 'Sepsis Rick Acknowleged - Infection/Sepsis...',
    status2: '2/26/18 10:51 [Sepsis Nurse Triage Orderset Initiated]'
  },
  {
    key: '2',
    pLoc: 'ED',
    rb: 'E18-03',
    pName: 'Patient B',
    ag: 'Male 46',
    pClass: 'Inpatient',
    pFlag: 'No',
    scoInfo: {
      first: 5,
      sec: 5,
      third: 'Never r...',
    },
    dt: {
      first: '2/26/18 02:51[5]',
      second: '2/26/18 02:20[5]',
    },
    rInfo: {
      topleft: '3%',
      botleft: 3, topright: '-',
      botright: '-',
    },
    rComm: '-',
    status2: '-'
  },

  {
    key: '3',
    pLoc: 'Home',
    rb: 'E34-01',
    pName: 'Patient C',
    ag: 'Female 60',
    pClass: 'Observe',
    pFlag: 'No',
    scoInfo: {
      first: 4,
      sec: 5,
      third: 'Never r...',
    },
    dt: {
      first: '2/26/18 02:51[2]',
      second: '2/26/18 02:20[2]',
    },
    rInfo: {
      topleft: '2%',
      botleft: 2, topright: '-',
      botright: '-',
    },
    rComm: '-',
    status2: '-'
  },
];

function Analysis() {
  const navigate = useNavigate();

  const handleRowClick = (record, rowIndex) => {
    if (rowIndex === 0) {
      navigate("/home/1")
    } else {
      console.log("Row Clicked:", record);
    }
  };

  return (
    <Table
      columns={columns}
      dataSource={data}
      onRow={(record, rowIndex) => {
        return {
          onClick: () => handleRowClick(record, rowIndex),
        };
      }
      }
    />);
}

export default Analysis;

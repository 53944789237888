import classNames from "classnames"
import './Card.scss';
const Card = ({ className, title, children, color, style }) => {
    console.log(title);
    return (
        <div className={classNames('card', { 'colorcard': color }, className)} style={{ '--card-color': color, ...style }}>
            {title && <div className="card__title">{title}</div>}
            <div className="card__content">{children}</div>
        </div>
    );
}
export default Card;

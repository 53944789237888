import React from 'react';
import classNames from 'classnames';
import './CustomButton.scss';

function CustomButton({ fillPercentage, label, active, onClick }) {
    const fillWidth = fillPercentage ? `${fillPercentage}%` : undefined;

    return (
        <button
            className={classNames('customButton', { active })}
            onClick={onClick}
            style={{
                '--fill-width': fillWidth,
                color: 'black',
            }}
        >
            <div className="customButton__fill"></div>
            {
                active && <div className='customButton__active_check'>
                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.8327 3.25L4.87435 9.20833L2.16602 6.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            }
            <span>{label}</span>
        </button>
    );
}

export default CustomButton;

import React, { useState } from 'react';
import { Row, Col, Divider } from 'antd';
import RoundTag from './Roundtag';
import './LeftBottom.css';

const LeftBottom = ({ lab_test }) => {
  // Initialize the expandedTestId state as null (no tests expanded initially)
  const [expandedTestId, setExpandedTestId] = useState(null);

  // Toggle the expansion of the clicked test
  const handleExpandToggle = (testId) => {
    if (expandedTestId === testId) {
      setExpandedTestId(null); // If the clicked test is already expanded, collapse it
    } else {
      setExpandedTestId(testId); // If not, expand it
    }
  };

  return (
    <>
      { /* Lab Test Result */}
      <Row>
        <Col style={{ fontWeight: '500' }} flex="50px 1 1">
          Date <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 9L0.803848 0L11.1962 0L6 9Z" fill="#424242" />
          </svg>

        </Col>
        <Col style={{ fontWeight: '500' }} flex="50px 1 1">
          Item <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 9L0.803848 0L11.1962 0L6 9Z" fill="#424242" />
          </svg>

        </Col>
      </Row>

      <Divider orientation="left" style={{ margin: '12px 0' }}></Divider>

      {/* Iterate thru lab test */}
      {Object.entries(lab_test).map(([date, data], index) => (
        <div key={index}>
          <Row style={{ padding: '15px' }}>
            <Col flex="50px 1 1">
              {date}
            </Col>
            <Col flex="50px 1 1" style={{
              display: 'flex',

            }}>
              {data.panel}
              <div style={{ flex: '1' }}></div>
              <div
                style={{
                  color: "purple",
                  textDecoration: "underline",
                  cursor: "pointer",
                  textAlign: "right"
                }}
                flex="100px"
                onClick={() => handleExpandToggle(date)}
              >
                {expandedTestId === date ? "Hide" : "Show"}
              </div>
            </Col>

          </Row>

          {expandedTestId === date && (
            <table style={{ width: '100%', borderCollapse: 'collapse', textAlign: 'left' }}>
              <tr style={{ padding: '9px', margin: '3px', border: '1px solid black' }}>
                <th style={{ paddingLeft: '15px' }}>
                  Item <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 9L0.803848 0L11.1962 0L6 9Z" fill="#424242" />
                  </svg>
                </th>
                <th>
                  Value
                </th>
                <th>
                  Reference
                </th>
              </tr>
              <tbody style={{ padding: '5px', margin: '5px', border: '1px solid black' }}>
                {Object.entries(data.item).map(([itemName, itemData], subIndex) => (
                  <tr key={subIndex} style={{ padding: '2px', margin: '3px' }}>
                    <td style={{ paddingLeft: '15px' }}>{itemName}</td>
                    <td style={{ display: 'flex', alignItems: 'center' }}>
                      <span style={{ flex: '40px 0 0' }}>{itemData.Value}</span>
                      {itemData.Status === 'high' && <svg width="8" height="10" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.35355 0.646447C4.15829 0.451184 3.84171 0.451184 3.64645 0.646447L0.464466 3.82843C0.269204 4.02369 0.269204 4.34027 0.464466 4.53553C0.659728 4.7308 0.976311 4.7308 1.17157 4.53553L4 1.70711L6.82843 4.53553C7.02369 4.7308 7.34027 4.7308 7.53553 4.53553C7.7308 4.34027 7.7308 4.02369 7.53553 3.82843L4.35355 0.646447ZM3.5 1V16H4.5V1H3.5Z" fill="#FF0000" />
                      </svg>
                      }
                      {
                        itemData.Status === 'low' && <svg width="8" height="10" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M3.64645 15.3536C3.84171 15.5488 4.15829 15.5488 4.35355 15.3536L7.53553 12.1716C7.7308 11.9763 7.7308 11.6597 7.53553 11.4645C7.34027 11.2692 7.02369 11.2692 6.82843 11.4645L4 14.2929L1.17157 11.4645C0.976311 11.2692 0.659728 11.2692 0.464466 11.4645C0.269204 11.6597 0.269204 11.9763 0.464466 12.1716L3.64645 15.3536ZM3.5 0V15H4.5V0H3.5Z" fill="#FF0000" />
                        </svg>
                      }
                      {
                        itemData.Status === 'normal' && <svg width="7" height="3" viewBox="0 0 7 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M6.78409 0.982954V2.54545H0.420455V0.982954H6.78409Z" fill="#00AB44" />
                        </svg>

                      }
                    </td>
                    <td>{itemData.Reference}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      ))}
    </>
  );
};

export default LeftBottom;

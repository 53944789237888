import React from 'react';
import { Row, Col } from 'antd';
import { Link, Outlet, useParams } from 'react-router-dom';

import LeftTop from './LeftTop';
import LeftBottom from './LeftBottom';
import RightSide from './RightSide';
import Card from './Card';
import Roundtag from './Roundtag';
import './Home.scss';

const data = {
  1: {
    "name": "John Smith",
    "age": 67,
    "sex": "male",
    "index": "E78-02",
    "predicted_sepsis_risk": 2,
    "id": 1,
    "race": "WHITE",
    "team": "Dr. Theresa Webb, Arlene McCoy",
    "lab_test": {
      "2023/09/17 12:32:56": {
        "panel": "CBC",
        "item": {
          "WBC": {
            "Value": 18.2,
            "Reference": "3.8 - 10.4(x103/microL)",
            "Status": "high"
          },
          "RBC": {
            "Value": 4.7,
            "Reference": "4.2 - 5.7 (x106/microL)",
            "Status": "normal"
          },
          "MCV": {
            "Value": 90,
            "Reference": "82.5 - 98 (fL)",
            "Status": "normal"
          },
          "MCH": {
            "Value": 29.8,
            "Reference": "32.5 - 35.2",
            "Status": "low"
          },
          "MCHC": {
            "Value": 33.4,
            "Reference": "11.4 - 13.5 (%)",
            "Status": "high"
          },
          "RDW": {
            "Value": 16.4,
            "Reference": "11.5 - 14.5 (%)",
            "Status": "high"
          },
          "PLT": {
            "Value": 167,
            "Reference": "140 - 450 (x103 /ul)",
            "Status": "normal"
          },
          "Neutrophil #": {
            "Value": 88.5,
            "Reference": "35 - 75 (%)",
            "Status": "high"
          },
          "Lymphocyte #": {
            "Value": 5.3,
            "Reference": "20 - 55 (%)",
            "Status": "low"
          },
          "Monocyte #": {
            "Value": 2.9,
            "Reference": "4 - 10 (%)",
            "Status": "low"
          },
          "Eosinophil #": {
            "Value": 1.2,
            "Reference": "0 - 5 (%)",
            "Status": "normal"
          },
          "Basophil #": {
            "Value": 0.3,
            "Reference": "0 - 2 (%)",
            "Status": "normal"
          }
        }
      },
      "2023/09/17 11:11:35": {
        "panel": "LHD",
        "item": {
        }
      },
      "2023/09/17 09:27:17": {
        "panel": "C7PMC",
        "item": {
        }
      },
      "2023/09/17 08:11:24": {
        "panel": "BMP",
        "item": {
        }
      },
      "2023/09/17 07:12:34": {
        "panel": "Procalcitonin",
        "item": {
        }
      },
      "2023/09/16 20:27:07": {
        "panel": "CBC",
        "item": {
        }
      }
    },
    "sepsis_prediction": {
      "now": 9,
      "current": {
        "prediction": {
          "0": 2,
          "3": 2,
          "6": 3,
          "9": 3,
          "12": 3,
          "15": 3,
          "18": 3,
          "21": 3,
          "24": 3,
          "27": 3,
          "30": 3
        },
        "uncertainty_upper": {
          "0": 2,
          "3": 2,
          "6": 3,
          "9": 3,
          "12": 4,
          "15": 4,
          "18": 4,
          "21": 4,
          "24": 4,
          "27": 4,
          "30": 4
        },
        "uncertainty_lower": {
          "0": 2,
          "3": 2,
          "6": 3,
          "9": 3,
          "12": 2,
          "15": 2,
          "18": 1,
          "21": 1,
          "24": 1,
          "27": 1,
          "30": 1
        }
      },
      "recommendation": {
        "Lactate": {
          "importance": 5,
          "trend": {
            "prediction": {
              "0": 2,
              "3": 2,
              "6": 3,
              "9": 3,
              "12": 3,
              "15": 3,
              "18": 3,
              "21": 3,
              "24": 3,
              "27": 3,
              "30": 3
            },
            "uncertainty_upper": {
              "0": 2,
              "3": 2,
              "6": 3,
              "9": 3,
              "12": 4,
              "15": 4,
              "18": 4,
              "21": 4,
              "24": 4,
              "27": 4,
              "30": 4
            },
            "uncertainty_lower": {
              "0": 2,
              "3": 2,
              "6": 2,
              "9": 2,
              "12": 2,
              "15": 2,
              "18": 1,
              "21": 1,
              "24": 1,
              "27": 1,
              "30": 1
            }
          }
        },
        "Creatinine": {
          "importance": 5,
          "trend": {
            "prediction": {
              "0": 2,
              "3": 2,
              "6": 3,
              "9": 3,
              "12": 4,
              "15": 5,
              "18": 17,
              "21": 28,
              "24": 35,
              "27": 42,
              "30": 46
            },
            "uncertainty_upper": {
              "0": 2,
              "3": 2,
              "6": 3,
              "9": 3,
              "12": 4,
              "15": 5,
              "18": 25,
              "21": 45,
              "24": 50,
              "27": 60,
              "30": 70
            },
            "uncertainty_lower": {
              "0": 2,
              "3": 2,
              "6": 3,
              "9": 3,
              "12": 4,
              "15": 5,
              "18": 8,
              "21": 13,
              "24": 20,
              "27": 24,
              "30": 28
            }
          }
        },
        "WBC": {
          "importance": 2,
          "trend": {}
        },
        "C-Reactive": {
          "importance": 2,
          "trend": {}
        },
        "SGPT": {
          "importance": 1,
          "trend": {}
        },
        "LactateCreatinine": {
          "trend": {
            "prediction": {
              "0": 2,
              "3": 2,
              "6": 3,
              "9": 3,
              "12": 4,
              "15": 5,
              "18": 17,
              "21": 28,
              "24": 35,
              "27": 42,
              "30": 45
            },
            "uncertainty_upper": {
              "0": 2,
              "3": 2,
              "6": 3,
              "9": 3,
              "12": 4,
              "15": 5,
              "18": 25,
              "21": 40,
              "24": 45,
              "27": 48,
              "30": 55
            },
            "uncertainty_lower": {
              "0": 2,
              "3": 2,
              "6": 3,
              "9": 3,
              "12": 4,
              "15": 5,
              "18": 8,
              "21": 20,
              "24": 28,
              "27": 32,
              "30": 35
            }
          }
        }
      }
    },
  },
  2: {
    "name": "Jane Doe",
    "age": 54,
    "sex": "female",
    "index": "B56-98",
    "predicted_sepsis_risk": 8,
    "id": 2,
    "race": "ASIAN",
    "team": "Dr. Benjamin Grant, Patricia Turner",

    "lab_test": {
      "2023/06/11 12:32:56": {
        "panel": "CBC",
        "item": {
          "WBC": {
            "Value": 23.7,
            "Reference": "3.8 - 10.4(x103/microL)",
            "Status": "high"
          },
          "RBC": {
            "Value": 4.06,
            "Reference": "4.2 - 5.7 (x106/microL)",
            "Status": "low"
          },
          "MCV": {
            "Value": 87,
            "Reference": "82.5 - 98 (fL)",
            "Status": "normal"
          },
          "MCH": {
            "Value": 27.7,
            "Reference": "32.5 - 35.2",
            "Status": "low"
          },
          "MCHC": {
            "Value": 31.7,
            "Reference": "11.4 - 13.5 (%)",
            "Status": "high"
          },
          "RDW": {
            "Value": 17.8,
            "Reference": "11.5 - 14.5 (%)",
            "Status": "high"
          },
          "PLT": {
            "Value": 157,
            "Reference": "140 - 450 (x103 /ul)",
            "Status": "normal"
          },
          "Neutrophil #": {
            "Value": 91.8,
            "Reference": "35 - 75 (%)",
            "Status": "high"
          },
          "Lymphocyte #": {
            "Value": 4.7,
            "Reference": "20 - 55 (%)",
            "Status": "low"
          },
          "Monocyte #": {
            "Value": 2.6,
            "Reference": "4 - 10 (%)",
            "Status": "low"
          },
          "Eosinophil #": {
            "Value": 0.8,
            "Reference": "0 - 5 (%)",
            "Status": "normal"
          },
          "Basophil #": {
            "Value": 0.2,
            "Reference": "0 - 2 (%)",
            "Status": "normal"
          }
        }

      },
      "2023/06/11 11:11:35": {
        "panel": "LHD",
        "item": {}
      },
      "2023/06/11 09:27:17": {
        "panel": "C7PMC",
        "item": {}
      },
      "2023/06/1108:11:24": {
        "panel": "BMP",
        "item": {}
      },
      "2023/06/1107:12:34": {
        "panel": "Procalcitonin",
        "item": {}
      },
      "2023/06/10 20:27:07": {
        "panel": "CBC",
        "item": {}
      }
    },
    "sepsis_prediction": {
      "now": 15,
      "current": {
        "prediction": {
          "0": 2,
          "3": 2,
          "6": 3,
          "9": 3,
          "12": 4,
          "15": 5,
          "18": 15,
          "21": 27,
          "24": 35,
          "27": 37,
          "30": 50
        },
        "uncertainty_upper": {
          "0": 2,
          "3": 2,
          "6": 3,
          "9": 3,
          "12": 4,
          "15": 5,
          "18": 25,
          "21": 50,
          "24": 60,
          "27": 65,
          "30": 75
        },
        "uncertainty_lower": {
          "0": 2,
          "3": 2,
          "6": 3,
          "9": 3,
          "12": 4,
          "15": 5,
          "18": 5,
          "21": 5,
          "24": 10,
          "27": 17,
          "30": 25
        }
      },
      "recommendation": {
        "Lactate": {
          "importance": 80,
          "trend": {
            "prediction": {
              "0": 2,
              "3": 2,
              "6": 3,
              "9": 3,
              "12": 4,
              "15": 5,
              "18": 15,
              "21": 30,
              "24": 35,
              "27": 40,
              "30": 45
            },
            "uncertainty_upper": {
              "0": 2,
              "3": 2,
              "6": 3,
              "9": 3,
              "12": 4,
              "15": 5,
              "18": 20,
              "21": 45,
              "24": 45,
              "27": 50,
              "30": 60
            },
            "uncertainty_lower": {
              "0": 2,
              "3": 2,
              "6": 3,
              "9": 3,
              "12": 4,
              "15": 5,
              "18": 10,
              "21": 15,
              "24": 25,
              "27": 30,
              "30": 30
            }
          }
        },
        "Creatinine": {
          "importance": 50,
          "trend": {
            "prediction": {
              "0": 2,
              "3": 2,
              "6": 3,
              "9": 3,
              "12": 4,
              "15": 5,
              "18": 17,
              "21": 28,
              "24": 35,
              "27": 42,
              "30": 46
            },
            "uncertainty_upper": {
              "0": 2,
              "3": 2,
              "6": 3,
              "9": 3,
              "12": 4,
              "15": 5,
              "18": 25,
              "21": 45,
              "24": 50,
              "27": 60,
              "30": 70
            },
            "uncertainty_lower": {
              "0": 2,
              "3": 2,
              "6": 3,
              "9": 3,
              "12": 4,
              "15": 5,
              "18": 8,
              "21": 13,
              "24": 20,
              "27": 24,
              "30": 28
            }
          }
        },
        "WBC": {
          "importance": 30,
          "trend": {}
        },
        "C-Reactive": {
          "importance": 15,
          "trend": {}
        },
        "SGPT": {
          "importance": 10,
          "trend": {}
        },
        "LactateCreatinine": {
          "trend": {
            "prediction": {
              "0": 2,
              "3": 2,
              "6": 3,
              "9": 3,
              "12": 4,
              "15": 5,
              "18": 17,
              "21": 28,
              "24": 35,
              "27": 42,
              "30": 45
            },
            "uncertainty_upper": {
              "0": 2,
              "3": 2,
              "6": 3,
              "9": 3,
              "12": 4,
              "15": 5,
              "18": 25,
              "21": 40,
              "24": 45,
              "27": 48,
              "30": 55
            },
            "uncertainty_lower": {
              "0": 2,
              "3": 2,
              "6": 3,
              "9": 3,
              "12": 4,
              "15": 5,
              "18": 8,
              "21": 20,
              "24": 28,
              "27": 32,
              "30": 35
            }
          }
        }
      }
    },
  },
  3:
  {
    "name": "Robert Johnson",
    "age": 72,
    "sex": "male",
    "index": "G12-45",
    "predicted_sepsis_risk": 15,
    "id": 3
  },
  4: {
    "name": "Emily Davis",
    "age": 62,
    "sex": "female",
    "index": "L89-01",
    "predicted_sepsis_risk": 7,
    "id": 4
  },
  5: {
    "name": "Michael Miller",
    "age": 69,
    "sex": "male",
    "index": "P34-67",
    "predicted_sepsis_risk": 11,
    "id": 5
  },
  6: {
    "name": "Elizabeth Taylor",
    "age": 58,
    "sex": "female",
    "index": "Q23-56",
    "predicted_sepsis_risk": 9,
    "id": 6
  },
  7: {
    "name": "James Brown",
    "age": 73,
    "sex": "male",
    "index": "R67-89",
    "predicted_sepsis_risk": 14,
    "id": 7
  },
  8: {
    "name": "Patricia Johnson",
    "age": 65,
    "sex": "female",
    "index": "S12-34",
    "predicted_sepsis_risk": 10,
    "id": 8
  },
  9: {
    "name": "William Davis",
    "age": 66,
    "sex": "male",
    "index": "T56-78",
    "predicted_sepsis_risk": 12,
    "id": 9
  },
  10: {
    "name": "Jennifer Wilson",
    "age": 60,
    "sex": "female",
    "index": "U90-12",
    "predicted_sepsis_risk": 8,
    "id": 10
  },
  11: {
    "name": "David Moore",
    "age": 68,
    "sex": "male",
    "index": "V34-56",
    "predicted_sepsis_risk": 13,
    "id": 11
  },
  12: {
    "name": "Linda Taylor",
    "age": 59,
    "sex": "female",
    "index": "W78-90",
    "predicted_sepsis_risk": 9,
    "id": 12
  },
  13: {
    "name": "Richard Anderson",
    "age": 70,
    "sex": "male",
    "index": "X12-34",
    "predicted_sepsis_risk": 14,
    "id": 13
  },
  14: {
    "name": "Barbara Thomas",
    "age": 61,
    "sex": "female",
    "index": "Y56-78",
    "predicted_sepsis_risk": 8,
    "id": 14
  },
  15: {
    "name": "Joseph Jackson",
    "age": 71,
    "sex": "male",
    "index": "Z90-12",
    "predicted_sepsis_risk": 15,
    "id": 15
  },
  16: {
    "name": "Susan White",
    "age": 63,
    "sex": "female",
    "index": "A34-56",
    "predicted_sepsis_risk": 7,
    "id": 16
  },
  17: {
    "name": "Charles Harris",
    "age": 64,
    "sex": "male",
    "index": "B78-90",
    "predicted_sepsis_risk": 12,
    "id": 17
  },
  18: {
    "name": "Jessica Martin",
    "age": 55,
    "sex": "female",
    "index": "C12-34",
    "predicted_sepsis_risk": 6,
    "id": 18
  },
  19: {
    "name": "Thomas Thompson",
    "age": 75,
    "sex": "male",
    "index": "D56-78",
    "predicted_sepsis_risk": 16,
    "id": 19
  },
  20: {
    "name": "Sarah Garcia",
    "age": 57,
    "sex": "female",
    "index": "E90-12",
    "predicted_sepsis_risk": 7,
    "id": 20
  },
  21: {
    "name": "Jane Doe",
    "age": 54,
    "sex": "female",
    "index": "B56-98",
    "predicted_sepsis_risk": 23,
    "id": 21,
    "race": "ASIAN",
    "team": "Dr. Benjamin Grant, Patricia Turner",

    "lab_test": {
      "2023/06/11 12:32:56": {
        "panel": "CBC",
        "item": {
          "WBC": {
            "Value": 23.7,
            "Reference": "3.8 - 10.4(x103/microL)",
            "Status": "high"
          },
          "RBC": {
            "Value": 4.06,
            "Reference": "4.2 - 5.7 (x106/microL)",
            "Status": "low"
          },
          "MCV": {
            "Value": 87,
            "Reference": "82.5 - 98 (fL)",
            "Status": "normal"
          },
          "MCH": {
            "Value": 27.7,
            "Reference": "32.5 - 35.2",
            "Status": "low"
          },
          "MCHC": {
            "Value": 31.7,
            "Reference": "11.4 - 13.5 (%)",
            "Status": "high"
          },
          "RDW": {
            "Value": 17.8,
            "Reference": "11.5 - 14.5 (%)",
            "Status": "high"
          },
          "PLT": {
            "Value": 157,
            "Reference": "140 - 450 (x103 /ul)",
            "Status": "normal"
          },
          "Neutrophil #": {
            "Value": 91.8,
            "Reference": "35 - 75 (%)",
            "Status": "high"
          },
          "Lymphocyte #": {
            "Value": 4.7,
            "Reference": "20 - 55 (%)",
            "Status": "low"
          },
          "Monocyte #": {
            "Value": 2.6,
            "Reference": "4 - 10 (%)",
            "Status": "low"
          },
          "Eosinophil #": {
            "Value": 0.8,
            "Reference": "0 - 5 (%)",
            "Status": "normal"
          },
          "Basophil #": {
            "Value": 0.2,
            "Reference": "0 - 2 (%)",
            "Status": "normal"
          }
        }

      },
      "2023/06/11 11:11:35": {
        "panel": "LHD",
        "item": {}
      },
      "2023/06/11 09:27:17": {
        "panel": "C7PMC",
        "item": {}
      },
      "2023/06/1108:11:24": {
        "panel": "BMP",
        "item": {}
      },
      "2023/06/1107:12:34": {
        "panel": "Procalcitonin",
        "item": {}
      },
      "2023/06/10 20:27:07": {
        "panel": "CBC",
        "item": {}
      }
    },
    "sepsis_prediction": {
      "now": 21,
      "current": {
        "prediction": {
          "0": 2,
          "3": 2,
          "6": 3,
          "9": 3,
          "12": 4,
          "15": 5,
          "18": 10,
          "21": 23,
          "24": 30,
          "27": 50,
          "30": 60
        },
        "uncertainty_upper": {
          "0": 2,
          "3": 2,
          "6": 3,
          "9": 3,
          "12": 4,
          "15": 5,
          "18": 10,
          "21": 23,
          "24": 50,
          "27": 67,
          "30": 70
        },
        "uncertainty_lower": {
          "0": 2,
          "3": 2,
          "6": 3,
          "9": 3,
          "12": 4,
          "15": 5,
          "18": 10,
          "21": 23,
          "24": 25,
          "27": 35,
          "30": 40
        }
      },
      "recommendation": {
        "Lactate": {
          "importance": 40,
          "trend": {
            "prediction": {
              "0": 2,
              "3": 2,
              "6": 3,
              "9": 3,
              "12": 4,
              "15": 5,
              "18": 15,
              "21": 30,
              "24": 35,
              "27": 40,
              "30": 45
            },
            "uncertainty_upper": {
              "0": 2,
              "3": 2,
              "6": 3,
              "9": 3,
              "12": 4,
              "15": 5,
              "18": 20,
              "21": 45,
              "24": 45,
              "27": 50,
              "30": 60
            },
            "uncertainty_lower": {
              "0": 2,
              "3": 2,
              "6": 3,
              "9": 3,
              "12": 4,
              "15": 5,
              "18": 10,
              "21": 15,
              "24": 25,
              "27": 30,
              "30": 30
            }
          }
        },
        "Creatinine": {
          "importance": 20,
          "trend": {
            "prediction": {
              "0": 2,
              "3": 2,
              "6": 3,
              "9": 3,
              "12": 4,
              "15": 5,
              "18": 17,
              "21": 28,
              "24": 35,
              "27": 42,
              "30": 46
            },
            "uncertainty_upper": {
              "0": 2,
              "3": 2,
              "6": 3,
              "9": 3,
              "12": 4,
              "15": 5,
              "18": 25,
              "21": 45,
              "24": 50,
              "27": 60,
              "30": 70
            },
            "uncertainty_lower": {
              "0": 2,
              "3": 2,
              "6": 3,
              "9": 3,
              "12": 4,
              "15": 5,
              "18": 8,
              "21": 13,
              "24": 20,
              "27": 24,
              "30": 28
            }
          }
        },
        "WBC": {
          "importance": 3,
          "trend": {}
        },
        "C-Reactive": {
          "importance": 5,
          "trend": {}
        },
        "SGPT": {
          "importance": 25,
          "trend": {}
        },
        "LactateCreatinine": {
          "trend": {
            "prediction": {
              "0": 2,
              "3": 2,
              "6": 3,
              "9": 3,
              "12": 4,
              "15": 5,
              "18": 17,
              "21": 28,
              "24": 35,
              "27": 42,
              "30": 45
            },
            "uncertainty_upper": {
              "0": 2,
              "3": 2,
              "6": 3,
              "9": 3,
              "12": 4,
              "15": 5,
              "18": 25,
              "21": 40,
              "24": 45,
              "27": 48,
              "30": 55
            },
            "uncertainty_lower": {
              "0": 2,
              "3": 2,
              "6": 3,
              "9": 3,
              "12": 4,
              "15": 5,
              "18": 8,
              "21": 20,
              "24": 28,
              "27": 32,
              "30": 35
            }
          }
        }
      }
    },
  },
}

const risk_to_color = (risk) => {
  if (risk >= 13) {
    return '#D30000';
  } else if (risk >= 5) {
    return '#FFD000';
  } else {
    return '#00B050';
  }
}

const risk_to_text_color = (risk) => {
  if (risk >= 13 || risk < 5) {
    return '#FFFFFF';
  } else {
    return '#000000';
  }
}

function Home() {
  const id = useParams().id;
  const patient = data[id];
  return (
    <div style={{
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#e6e6e6',
    }}>
      <Row style={{ height: '100%' }}>
        <Col span={4} style={{
          display: 'flex',
          flexDirection: 'column', height: '100%'
        }}>
          <Card className="list-card" style={{ flexGrow: 1, maxHeight: 'calc(100% - 32px)', marginRight: '0px' }}>
            <div class="list-item">
              <div class="title">
                Patients List
              </div>
            </div>
            {Object.entries(data).map(([index, patient]) => (
              <Link key={index} to={`/home/${patient.id}`} style={{ color: 'inherit', textDecoration: 'inherit' }}>
                <div key={index} class="list-item">
                  <div class="score">
                    <div class="tag" style={{
                      backgroundColor: risk_to_color(patient.predicted_sepsis_risk),
                      color: risk_to_text_color(patient.predicted_sepsis_risk)
                    }}>
                      {patient.predicted_sepsis_risk}
                    </div>
                  </div>
                  <div class="right">
                    <div class="name">
                      {patient.name}
                    </div>
                    <div class="detail">
                      <div>
                        {patient.age} y.o., {patient.sex}
                      </div>
                      <div>
                        {patient.index}
                      </div>
                    </div>
                  </div>
                </div>
              </Link>))
            }
          </Card>
        </Col>
        <Col span={20} style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column'
        }}>
          <Row style={{
            flex: 1,
            height: '100%',
            alignItems: 'flex-start',
          }}>
            <Col
              span={14}
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
              }}
            >
              <Card >

                <div style={{ marginBottom: '1px', display: 'flex', alignItems: 'center' }}>
                  <span style={{ fontSize: '20px', marginRight: '12px' }}>
                    {patient.name}
                  </span>
                  <span style={{ fontSize: '16px' }}>{patient.index}</span>
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1px' }}>
                  <span style={{ fontSize: '18px', fontWeight: '500', marginRight: '5px', lineHeight: '1.2' }}>
                    {patient.age}y.o. {patient.sex} &nbsp;&nbsp;&nbsp;{patient.race}
                  </span>

                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '5px' }}>
                      <span style={{ fontSize: '18px', lineHeight: '1.2' }}>
                        Sepsis Risk
                      </span>
                      <span style={{ fontSize: '18px', alignSelf: 'center', lineHeight: '1.2' }}>
                        Score
                      </span>
                    </div>
                    <div class="tag large" style={{
                      backgroundColor: risk_to_color(patient.predicted_sepsis_risk),
                      color: risk_to_text_color(patient.predicted_sepsis_risk)
                    }}>
                      {patient.predicted_sepsis_risk}
                    </div>
                  </div>
                </div>

                <div>
                  <span style={{ fontSize: '16px', lineHeight: '1.2' }}>Treatment Team:  {patient.team}</span>
                </div>
              </Card>
              <Card className='bottom' color="#4A239C" title={(
                <Row style={{/*padding: '5px'*/ }}>
                  <Roundtag backgroundColor='#DAC9FF'>
                    Lab Test Result
                  </Roundtag>
                  <Roundtag backgroundColor='#FFD1B0'>
                    Vital Signs
                  </Roundtag>
                  <Roundtag backgroundColor='#D3FCFF'>
                    Summary
                  </Roundtag>
                </Row>
              )}>
                <LeftBottom lab_test={patient.lab_test} />
              </Card>
            </Col>
            <Col
              span={10}
              style={{
                backgroundColor: '#e6e6e6',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <RightSide sepsis_prediction={patient.sepsis_prediction} key={patient.id}/>
            </Col>
          </Row>
        </Col>
      </Row>
    </div >
  );
}

export default Home;

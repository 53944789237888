import React from 'react';
import { Route, Routes, HashRouter } from 'react-router-dom';
import Home from './Components/Home';
import Analysis from './Components/Analysis';

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/home/:id" element={<Home />}>
        </Route>
        <Route path="/analysis" element={<Analysis />} />
    </Routes>
    </HashRouter>
  );
}

export default App;

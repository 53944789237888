import React, { useEffect, useState } from "react";
import {
  XAxis,
  YAxis,
  Area,
  Line,
  ComposedChart,
  ResponsiveContainer,
  ReferenceLine,
  Label,
  // Tooltip,
  // Button,
} from "recharts";
import { Row, Col, Divider, Button } from "antd"
import './RightSide.css'
import CustomButton from "./CustomButton";
import Roundtag from "./Roundtag";
import {Link} from 'react-router-dom'
import Card from "./Card";


function RightSide({ sepsis_prediction }) {
  // const [showPredictions, setShowPredictions] = useState(false);
  // const [showPredictions1, setShowPredictions1] = useState(false);
  // const [showPredictions2, setShowPredictions2] = useState(false);

  // const togglePredictionsVisibility = () => {
  //   setShowPredictions(!showPredictions);
  // };

  // const togglePredictionsVisibility1 = () => {
  //   setShowPredictions1(!showPredictions1);
  // };

  // const togglePredictionsVisibility2 = () => {
  //   setShowPredictions2(!showPredictions2);
  // };

  const data = Object.keys(sepsis_prediction.current.prediction).map(key => {
    return {
      x: parseInt(key),

      line_up: sepsis_prediction.current.uncertainty_upper[key],
      line_low: sepsis_prediction.current.uncertainty_lower[key],
      line_curr: sepsis_prediction.current.prediction[key],

      line_prediction: sepsis_prediction.recommendation.Lactate.trend.prediction[key],
      predict_up: sepsis_prediction.recommendation.Lactate.trend.uncertainty_upper[key],
      predict_low: sepsis_prediction.recommendation.Lactate.trend.uncertainty_lower[key],

      cre_p: sepsis_prediction.recommendation.Creatinine.trend.prediction[key],
      cre_h: sepsis_prediction.recommendation.Creatinine.trend.uncertainty_upper[key],
      cre_l: sepsis_prediction.recommendation.Creatinine.trend.uncertainty_lower[key],

      lc_p: sepsis_prediction.recommendation.LactateCreatinine.trend.prediction[key],
      lc_h: sepsis_prediction.recommendation.LactateCreatinine.trend.uncertainty_upper[key],
      lc_l: sepsis_prediction.recommendation.LactateCreatinine.trend.uncertainty_lower[key],
    };
  });

  const dataWithRange = data.map((d) => ({
    ...d,
    range: [d.line_low, d.line_up],
    predictRange: [d.predict_low, d.predict_up],
    predictRange1: [d.cre_l, d.cre_h],
    predictRange2: [d.lc_l, d.lc_h]
  }));

  const sortedBtns = Object.keys(sepsis_prediction.recommendation).map((t) => ({
    name: t,
    importance: sepsis_prediction.recommendation[t].importance
  })).sort((a, b) => b.importance - a.importance).filter(t => t.name !== 'LactateCreatinine');

  const stateInit = {};
  sortedBtns.forEach(t => {
    stateInit[t.name] = false;
  });

  const [showPrediction, setPrediction]  = useState(stateInit);

  const togglePredictionsVisibility = (name) => () => {
    console.log(name);
    const x = JSON.parse(JSON.stringify(showPrediction));
    x[name] = !x[name];
    console.log(x);
    setPrediction(x);
  }

  return (
    <Card color='#e6372e' title={
      <Roundtag backgroundColor="#FFDBE1">
        Lab Test Recommendation
      </Roundtag>} style={{ height: '100%', marginLeft: 0 }}>

      <Row gutter={40} style={{marginBottom: '48px'}}>
        <Col span="24">
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
            {sortedBtns.map(btn => (

              <div style={{ padding: '5px 30px' }} key={btn.name}>
                <CustomButton
                  fillPercentage={sepsis_prediction.recommendation[btn.name].importance}
                  label={btn.name}
                  active={showPrediction[btn.name]}
                  onClick={togglePredictionsVisibility(btn.name)}
                />
              </div>

            ))}
            {/* <div style={{ padding: '5px 30px' }}>
              <CustomButton
                fillPercentage={sepsis_prediction.recommendation.Lactate.importance}
                label="Lactate"
                active={showPredictions}
                onClick={togglePredictionsVisibility}
              />
            </div>

            <div style={{ padding: '5px 30px' }}>
              <CustomButton
                fillPercentage={sepsis_prediction.recommendation.Creatinine.importance}
                label="Creatinine"
                active={showPredictions1}
                onClick={togglePredictionsVisibility1}
              />
            </div>

            <div style={{ padding: '5px 30px' }}>
              <CustomButton
                fillPercentage={sepsis_prediction.recommendation.WBC.importance}
                label="WBC"
                active={showPredictions2}
                onClick={togglePredictionsVisibility2}
              />
            </div>

            <div style={{ padding: '5px 30px' }}>
              <CustomButton
                fillPercentage={sepsis_prediction.recommendation["C-Reactive"].importance}
                label="C-Reactive"
              // showBorder={showPredictions}
              // onClick={ togglePredictionsVisibility }
              />
            </div>

            <div style={{ padding: '5px 30px' }}>
              <CustomButton
                fillPercentage={sepsis_prediction.recommendation.SGPT.importance}
                label="SGPT"
              // showBorder={showPredictions}
              // onClick={ togglePredictionsVisibility }
              />
            </div> */}
          </div>
        </Col>
      </Row>

      {/* start of interactive graph */}
      <ResponsiveContainer width="100%" height="400" aspect={2}>
        <ComposedChart
          data={dataWithRange}
          margin={{
            top: 20,
            right: 70,
            bottom: 20,
            left: 10,
          }}
        >
          <XAxis
            dataKey="x"
            type="number"
            // tickLine={false}
            // tick={() => null}
            strokeWidth={1}
          >
            <Label value="Time" offset={0} position="insideBottom" />
          </XAxis>
          <YAxis
            tickCount={5}
            // tickLine={false}
            // allowDecimals={false}
            strokeWidth={1}
            domain={[0, 100]}
          >

            <Label value="Risk score" offset={10} position="top" />
          </YAxis>
          {/* <Tooltip /> */}
          <Area
            dataKey="range"
            stroke="#8884d8"
            strokeWidth={0}
            fill="rgba(217, 217, 217, 1)"

          />

          <Line
            type="linear"
            dataKey="line_up"
            strokeWidth={1.8}
            stroke="black"
            isAnimationActive={false}
            dot={false}
          />
          <Line
            type="linear"
            dataKey="line_low"
            strokeWidth={1.8}
            stroke="black"
            isAnimationActive={false}
            dot={false}
          />
          <Line
            type="linear"
            dataKey="line_curr"
            strokeWidth={1.8}
            stroke="black"
            strokeDasharray="10 5"
            isAnimationActive={false}
            dot={false}
          />

          {showPrediction['Lactate'] && !showPrediction['Creatinine'] && (
            <>
              <Area
                dataKey="predictRange"
                stroke="#72B6BA"
                strokeWidth={0}
                fill="rgba(255, 184, 197, 1)"
                isAnimationActive={false}
              />
              <Line
                type="linear"
                dataKey="predict_low"
                strokeWidth={2}

                stroke="#FF0000"

                isAnimationActive={false}
                dot={false}
              />
              <Line
                type="linear"
                dataKey="predict_up"
                strokeWidth={2}
                stroke="#FF0000"
                isAnimationActive={false}
                dot={false}
              />
              <Line
                type="linear"
                dataKey="line_prediction"
                strokeWidth={2}
                stroke="#FF0000"
                strokeDasharray="10 5"
                isAnimationActive={false}
                dot={false}
              />
            </>
          )}

          {/* show Creatinine */}

          {showPrediction['Creatinine'] && !showPrediction['Lactate'] && (
            <>
              <Area
                isAnimationActive={false}
                dataKey="predictRange1"
                stroke="#72B6BA"
                strokeWidth={0}
                fill="rgba(255, 184, 197, 1)"
              />
              <Line
                type="linear"
                isAnimationActive={false}
                dataKey="cre_l"
                strokeWidth={2}
                stroke="#FF0000"
                dot={false}
              />
              <Line
                type="linear"
                dataKey="cre_h"
                isAnimationActive={false}
                strokeWidth={2}
                stroke="#FF0000"
                dot={false}
              />
              <Line
                type="linear"
                isAnimationActive={false}
                dataKey="cre_p"
                strokeWidth={2}
                stroke="#FF0000"
                strokeDasharray="10 5"
                dot={false}
              />
            </>
          )}
          {/* end of show Creatinine */}

          {/* show both */}

          {showPrediction['Creatinine'] && showPrediction['Lactate'] && (
            <>
            <Area
              dataKey="predictRange2"
              stroke="#FF0000"
              strokeWidth={0}
              isAnimationActive={false}
              fill="rgba(255, 184, 197, 1)"
            />
              <Line
                type="linear"
                dataKey="lc_h"
                isAnimationActive={false}
                strokeWidth={2}
                stroke="#FF0000"
                dot={false}
              />
              <Line
                type="linear"
                dataKey="lc_l"
                strokeWidth={2}
                isAnimationActive={false}
                stroke="#FF0000"
                dot={false}
              />
              <Line
                type="linear"
                dataKey="lc_p"
                strokeWidth={2}
                isAnimationActive={false}
                stroke="#FF0000"
                strokeDasharray="10 5"
                dot={false}
              />
            </>
          )}


          <ReferenceLine x={sepsis_prediction.now} stroke="black" strokeDasharray="3 3">
            <Label value="Now" position={"top"} />
          </ReferenceLine>
          <ReferenceLine y="13" stroke="red" strokeDasharray="3 3">

            <Label value="13" position={"left"} />
          </ReferenceLine>

          {/* end of show both */}
        </ComposedChart>
      </ResponsiveContainer>
      <Link  to={`/home/21`} style={{ color: 'inherit', textDecoration: 'inherit', width: '300px', height: '100px', display: 'block', cursor: 'default' }}>
      </Link>
    </Card>
  );
};

export default RightSide;
